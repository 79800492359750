import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';

const SecureStorage = require('secure-web-storage');

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private url = environment.URL_BACKSAILS;

    secureStorage = new SecureStorage(localStorage, {
        hash: function hash(key) {
            key = CryptoJS.HmacSHA256(key, environment?.SECRET_KEY_STOREGE);
            return key.toString();
        },
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, environment?.SECRET_KEY_STOREGE);
            return data.toString();
        },
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, environment?.SECRET_KEY_STOREGE);
            return data.toString(CryptoJS.enc.Utf8);
        }
    })

    constructor(
        private http: HttpClient
    ) { }

    setJsonValue(key: string, value: any) {
        this.secureStorage.setItem(key, value);
    }
    getJsonValue(key: string) {
        return this.secureStorage.getItem(key);
    }
    clearToken() {
        return this.secureStorage.clear();
    }

    getPemisosAreaUsuario(idUsuario): Observable<any> {
        return this.http.get(this.url + `/user/${idUsuario}?select=createdAt,createBy,updateBy,activoLogico&populate=areas`) as Observable<any>;

    }
}
