<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/logo-01.png" alt="logo">
        <span>WA TMX 2224</span>
    </a>

    <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
        <i class="pi pi-bars"></i>
    </a>

    <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="appMain.toggleTopMenu($event)">
        <i class="pi pi-ellipsis-v"></i>
    </a>

    <div class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active':appMain.topMenuActive}">
         <!--a href="#" class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </a>
        <a href="#" class="p-link layout-topbar-button">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </a-->
        <a href="#" (click)="salir()" class="p-link layout-topbar-button"  pTooltip="Salir">
            <i class="pi pi-sign-out"></i>
            <span>Salir</span>
        </a>
    </div>
</div>
