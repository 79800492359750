// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SECRET_KEY_APIREST: '$%gHedgf96VDJ766@#$gHedgf96VDJ766',
  SECRET_KEY_STOREGE: "claveSecreta4121",
  //URL_BACKSAILS: 'http://localhost:1337',
  URL_BACKSAILS: 'https://whatsuptmx.temixco.gob.mx:7000/api',
  URL_BACKWA: 'http://localhost:3005',
  URL_HOST: 'https://whatsuptmx.temixco.gob.mx:7000'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
