import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    constructor(
        private LocalService: LocalStorageService,
        private router: Router,
        private authenticationService: AuthenticationService) {

    }
    cerrarSesion() {
            this.LocalService.clearToken();
            this.router.navigate(['login']);
            window.location.reload();
    }
}
