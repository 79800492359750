import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {

    constructor(
        private auth: AuthorizationService,
        private router: Router
    ) { }

    canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
    this.router.navigate(['login']);
    return true;
}
return true;
    }

}
