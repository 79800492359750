import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';



import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';

import { LandingComponent } from './pages/landing/landing.component';
import { AppLoginComponent } from './pages/login/app.login.component';
import { ErrorComponent } from './pages/error/error.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AccessComponent } from './pages/access/access.component';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ConfigService } from './modules/template/service/app.config.service';
import { MenuService } from './modules/template/service/app.menu.service';
import { ToastModule } from 'primeng/toast';
import {BlockUIModule} from 'primeng/blockui';
import { TokenInterceptor } from './interceptors/token.interceptor';



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PasswordModule,
        DividerModule,
        RippleModule,
        StyleClassModule,
        ToastModule,
        BlockUIModule

    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        LandingComponent,
        AppLoginComponent,
        ErrorComponent,
        NotfoundComponent,
        AccessComponent,

    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        ConfigService, MenuService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
